<template>
<div >
      <NavBar
  title="套餐详情"
  left-arrow
  @click-left="$router.go(-1)"
/>
  <h3  class="pakagetitle">{{healthpackage.title}}</h3>
  <vimg :src="require('@/assets/healthpackages/p1.png')" />
  <van-cell-group title="套餐名称">
    <van-cell :title="details.title" />
  </van-cell-group>
  <van-cell-group title="服务方式">
    <van-cell :title="details.service" />
  </van-cell-group>
  <van-cell-group title="数量">
    <van-cell title="N" />
  </van-cell-group>
  <van-cell-group title="单位">
    <van-cell :title="details.unit" />
  </van-cell-group>
  <van-cell-group title="单价(元)">
    <van-cell :title="details.price" />
  </van-cell-group>
  <van-cell-group title="金额(元)">
    <van-cell :title="details.price+'*N'" />
  </van-cell-group>
  <van-cell-group title="功能介绍-基础服务-微信公众号">
    <div v-for="(dtl,idx) in details.details" :key="idx">
      <van-cell v-if="dtl.service == '微信公众号'">
        <template #title>
          <span class="packege_title" >{{dtl.title}}</span>
        </template>
        <template #label>
          <span class="packege_desc">{{dtl.content}}</span>
        </template>
      </van-cell>
    </div>
  </van-cell-group>
  <van-cell-group title="功能介绍-个性化服务-线上咨询">
    <div v-for="(dtl,idx) in details.details" :key="idx">
      <van-cell v-if="dtl.service == '线上咨询'">
        <template #title>
          <span class="packege_title" >{{dtl.title}}</span>
        </template>
        <template #label>
          <span class="packege_desc">{{dtl.content}}</span>
        </template>
      </van-cell>
    </div>
  </van-cell-group>
<!--  <van-cell>-->
<!--    <template #title>-->
<!--      <span class="packege_title" >{{details.title}}</span>-->
<!--    </template>-->
<!--    <template #label>-->
<!--      <span class="packege_desc">{{details.des}}</span>-->
<!--    </template>-->
<!--  </van-cell>-->
<!--  <van-cell>-->
<!--    <template #title>-->
<!--      <span class="packege_title" >{{details.service}}</span>-->
<!--    </template>-->
<!--    <template #label>-->
<!--      <span class="packege_desc">{{details.des}}</span>-->
<!--    </template>-->
<!--  </van-cell>-->
<!--  <van-cell-->
<!--         v-for="(dtl,idx) in details"-->
<!--        :key="idx"  >-->
<!--  <template #title>-->
<!--    <span class="packege_title" >{{idx+1}}.{{dtl.title}}</span>-->
<!--  </template>-->
<!--   <template #label>-->
<!--    <span class="packege_desc">{{dtl.descr}}</span>-->
<!--  </template>-->
<!--  </van-cell>-->
 <van-button round block type="info" @click="$router.go(-1)" >返回</van-button>
</div>
</template>
<script>
import {Cell as VanCell,CellGroup as VanCellGroup,Button as VanButton,Image as vimg} from 'vant';
import {getPackageDetails,getHP} from '@/api/healthpackages';
export default {
  name: 'PakageDeatil',
  components: {
    VanCell,VanButton,vimg,VanCellGroup
  },
  data(){
    return {
       details:[
        
       ],
       healthpackage:{
         
       }
    };
  },
  methods:{  
     qPackageDetails(){
        getPackageDetails({id:this.$route.params.id},this.$route.params.id).then((response) => {
          console.log(response)
          if(response.code==200 ){
              this.details=response.data;
          }
    });
     },
      qHP(){
        getHP({hpid:this.$route.params.hpid}).then((response) => {
          if(response.code==200 ){
              this.healthpackage=response.data;
          }
     });
     }
  },
   created() {
     console.info();
     this.qPackageDetails();
     // this.qHP();
  },
}
</script>

<style scoped>
.packege_title{
    font-size: medium;
}

.packege_desc{
    font-size: small;
    color:#646566;
}
.pakagetitle{
    text-align: center;
}

</style>
